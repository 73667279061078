export const environment = {
    domain: 'staging',
    disablev6: true,
    originDomain: 'staging-cloudziti.io', // Allowed Origin in auth0 (root domain)
    originPort: false,
    production: true,
    showCreditCardHelp: true,
    showLoginNetworkWarning: true,
    gatewayUrl: 'gateway.staging.netfoundry.io',
    apiUrl: 'https://gateway.staging.netfoundry.io/rest/v1/',
    clientSyncsApiUlr: 'https://gateway.staging.netfoundry.io/clientsync/v1/',
    netflowUrl: 'https://gateway.staging.netfoundry.io/netflow/v1/',
    downloadsURL: 'https://netfoundry.io/resources/support/downloads/networkversion',
    customerURL: 'https://gateway.staging.netfoundry.io/customer/v1/',
    appsURL: 'https://gateway.staging.netfoundry.io/apps/v1/',
    bannerURL: 'https://gateway.staging.netfoundry.io/banner/v1/',
    kibanaURL: 'https://kibana-staging-netfoundry-io.kb.us-east-1.aws.found.io:9243/',
    elasticConfig: {
        usePublicApi: true,
        reportingServiceUrl: 'https://gateway.staging.netfoundry.io/reporting/v1/elastic',
        reportingServiceEnabled: true,
        reportingTemplateServiceUrl: 'https://gateway.staging.netfoundry.io/reporting/v1/template-search',
    },
    defaultNetworkConfigName: 'small',
    defaultSize: 'small',
    defaultLocationCode: 'us-east-1',
    authconfig: {
        clientID: 'yf1HOLPaVC12MMUNpmOtof6SBKjWg0kp',
        domain: 'netfoundry-staging.auth0.com',
        callbackURL: 'https://staging-cloudziti.io/callback',
        // Namespace for where the organization ID is stored in the auth token
        jwtOrgKey: 'https://netfoundry.io/organization_id',
        audience: 'https://gateway.staging.netfoundry.io/',
        newSignupRedirect: 'https://staging-cloudziti.io/signup-return',
    },
    identityConfig: {
        url: 'https://gateway.staging.netfoundry.io/identity/v1/',
        invitationUrl: 'https://staging-cloudziti.io/invitation',
        intermediateReturnUrl: 'https://staging-cloudziti.io/invitation',
        registrationReturnUrl: 'https://staging-cloudziti.io/signup',
        loginReturnUrl: 'https://staging-cloudziti.io/login',
    },
    billingConfig: {
        // this is subject to change
        billingUrl: 'https://gateway.staging.netfoundry.io/billing/v1/',
        // stripe test environment
        stripe: 'pk_test_gU2Exz3cYBQVOlnMMilWk8lv',
    },
    chargebeeConfig: {
        // this is subject to change
        site: 'netfoundry-test',
        // chargebee test environment
        publishableKey: 'test_zUjbDE8B2gvkLccdcu0oWYCmLkZLXyFxek',
    },
    // temporary property for the how long the session timeout should be. This should get replaced once the API supports session timeout
    defaultSessionTimeoutSeconds: 1800,
    // temporary property for how long before the session timeout the user should be warned about the timeout
    defaultTimeoutWarningSeconds: 60,
    // two hour timeout for endpoint session users
    endpointSessionTimeoutSeconds: 7200,
    pageSize: 30,
    // list of comma separated email ids of test robots. If the current profile's email id is matching with any of the given test user
    // email ids, then some of the functionalities will be hidden or diabled.
    internalTestUserEmailIds: 'nf_test5@netfoundry.io',
    logoClass: 'staging',
    faviconUrl: '/assets/icons/StagingFavicon.png',
    authorizationUrl: 'https://gateway.staging.netfoundry.io/auth/v1/',
    reportingUrl: 'https://gateway.staging.netfoundry.io/reports/v1',
    gtmLink:
        'https://www.googletagmanager.com/ns.html?id=GTM-TG778K4&gtm_auth=i727zO_m0Zr06DPRi9STEg&gtm_preview=env-15&gtm_cookies_win=x',
    v2apiUrl: 'https://gateway.staging.netfoundry.io/core/v2/',
    v3apiUrl: 'https://gateway.staging.netfoundry.io/core/v3/',
    notificationUrl: 'https://gateway.staging.netfoundry.io/notification/v1',
    registrationIp: '100.25.242.106,34.225.50.156',
    // TODO: remove when MOP can handle custom timeouts
    pldtTimeoutSeconds: 32000,
    pldtId: 'a37f806b-4f49-48f2-aa28-d850d5f24b8c',
    sampleServiceUrl: 'https://sample.tools.netfoundry.io',
    isCloudZiti: true,
    v3Enabled: true,
};
